<template>
  <div>
    <el-skeleton style="padding: 16px" :rows="16" v-if="carDetails.loading" animated/>

    <div v-else>
      <client-details-header />

      <el-tabs v-model="activeTab">
        <el-tab-pane label="Инфо" name="1">
          <client-details />
        </el-tab-pane>

        <el-tab-pane label="Заказы" name="2">
          Заказы
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { CAR_DETAILS_REQUEST, CAR_PURGE } from '@/store/actions/cars';
import { mapGetters } from 'vuex';
import ClientDetailsHeader from '@/views/Clients/parts/client-details-header';
import ClientDetails from '@/views/Clients/parts/client-details';

export default {
  name: 'ClientDetailsView',
  components: { ClientDetailsHeader, ClientDetails },
  data() {
    return {
      activeTab: '1',
    };
  },
  created() {
    if (this.$route.params.id === 'new') {
      this.$store.commit(CAR_PURGE);
    } else {
      this.$store.dispatch(CAR_DETAILS_REQUEST, this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters(['carDetails']),
  },
};
</script>

<style scoped>

</style>
