<template>
  <div class="flex__fill-height-parent">
    <div class="flex flex__j-sb flex__a-ac mb_24">
      <p class="text_18 text_dark text_b">
        Владелец
      </p>

      <button class="button__icon" :disabled="newCar" @click="$emit('update:ownerComponent', 'owner-edit-form')">
        <svg class="common__svg-blue cp" width="24" height="24">
          <use xlink:href="/img/svg/symbol.svg#edit" />
        </svg>
      </button>
    </div>

    <div v-if="newCar" class="flex flex__a-ac flex__j-sb flex__1">
      <p style="width: 260px" class="text_15 text_dark text_ac m_auto">
        Прежде чем вносить данные владельца, заполните информацию об автомобиле
      </p>
    </div>

    <el-skeleton style="padding: 16px" :rows="16" v-if="!newCar && ownerDetails.loading" animated/>

    <div v-else-if="!newCar && ownerDetails.owner">
      <p class="text_15 text_dark mb_4">
        {{userFullName}}
      </p>

      <p class="text_15 text_gray">
        {{ownerDetails.owner.phone | VMask('+# (###) ###-####')}}
      </p>

      <p class="text_15 text_gray mb_12">
        {{ownerDetails.owner.email}}
      </p>

      <p class="text_15 text_gray mb_14 mb_40">
        {{ownerDetails.owner.description}}
      </p>
    </div>
  </div>
</template>

<script>
import getUserFullName from '@/services/getUserFullName';
import { mapGetters } from 'vuex';

export default {
  name: 'owner-info',
  computed: {
    ...mapGetters(['ownerDetails', 'carDetails']),
    newCar() {
      return !this.carDetails.car;
    },
    userFullName() {
      return getUserFullName(this.ownerDetails.owner).fullName;
    },
  },
};
</script>

<style scoped>

</style>
