<template>
  <div class="flex flex__a-bl flex__j-sb mb_20">
    <div v-if="$route.params.id !== 'new'">
      <p class="text_32 text_dark text_b">
        {{carDetails.car.registration_number}}
      </p>

      <p class="text_15 text_gray">
        {{carDetails.car.vendor}} {{carDetails.car.model}}
      </p>
    </div>

    <p v-else class="text_32 text_dark text_b">
      Новый автомобиль
    </p>

    <button class="button__back"  @click="$router.push('/clients')">
      <svg class="common__svg-blue cp" width="20" height="16">
        <use xlink:href="/img/svg/symbol.svg#arrow" />
      </svg>

      Назад
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'car-details-header',
  computed: {
    ...mapGetters(['carDetails']),
  },
};
</script>

<style scoped>

</style>
