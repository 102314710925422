<template>
  <div>
    <div class="flex flex__j-sb flex__a-bl flex__w mb_24">
      <p class="text_18 text_dark text_b">
        Владелец
      </p>

      <div class="flex">
        <el-button class="el-button-32" type="" @click="$emit('update:ownerComponent', 'owner-info')">
          Отмена
        </el-button>

        <el-button class="el-button-32" type="primary" @click="handleValidate">
          Сохранить
        </el-button>
      </div>
    </div>

    <el-button v-if="!newOwner" class="el-button-32 mb_32" type="" @click="setNewOwner">
      Новый владелец
    </el-button>

    <p class="text_15 text_dark text_b mb_20">
      {{ newOwner ? 'Данные нового владельца' : 'Данные текущего владельца' }}
    </p>

    <owners-search v-if="newOwner" @setOwnerFromAutocomplete="setOwnerFromAutocomplete($event)" />

    <el-form class="common__form-vertical" :label-position="'top'">
      <el-form-item label="Фамилия">
        <el-input
          v-model="ownerEdit.last_name"
          name="last_name"
          placeholder="Иванов"
          :class="{'common__form-item-err' : errors.last_name}"
          @change="validate('last_name')"
        />
      </el-form-item>

      <el-form-item label="Имя">
        <el-input
          v-model="ownerEdit.first_name"
          name="first_name"
          placeholder="Иван"
          :class="{'common__form-item-err' : errors.first_name}"
          @change="validate('first_name')"
        />
      </el-form-item>

      <el-form-item label="Отчество">
        <el-input
          v-model="ownerEdit.patronymic"
          name="patronymic"
          placeholder="Иванович"
          :class="{'common__form-item-err' : errors.patronymic}"
          @change="validate('patronymic')"
        />
      </el-form-item>

      <el-form-item label="Телефон">
        <div :class="{'common__form-item-err' : errors.phone}" class="el-input el-input--suffix">
          <input v-model="ownerEdit.phone" v-mask="'+7 ### ###-####'" name="phone"
                 class="el-input__inner" @change="validate('phone')" @focus="setPhoneCode"/>
        </div>
      </el-form-item>

      <el-form-item label="Email">
        <div :class="{'common__form-item-err' : errors.email}" class="el-input el-input--suffix">
          <input v-model="ownerEdit.email" name="email"
                 class="el-input__inner" @change="validate('email')" />
        </div>
      </el-form-item>

      <el-form-item label="Описание">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="Напишите, чтобы не забыть..."
          v-model="ownerEdit.description">
        </el-input>
      </el-form-item>
    </el-form>

    <p v-if="showInfo" class="text_15 text_gray mt_24">
      {{
        !newOwner ? 'Чтобы добавить нового владельца, нажмите на&nbsp;кнопку “Новый владелец”.'
          : 'При добавлении нового владельца предыдущий останется в истории автомобиля.'
      }}
    </p>
  </div>
</template>

<script>
import { object, string } from 'yup';
import _ from 'lodash';
import phoneUnmask from '@/services/phoneUnmask';
import {
  OWNER_ADD_REQUEST,
  OWNER_SAVE_REQUEST,
  OWNERS_CHANGE_FILTERS, OWNERS_LIST_REQUEST,
} from '@/store/actions/owners';
import { mapGetters } from 'vuex';
import { CAR_SAVE_REQUEST } from '@/store/actions/cars';
import OwnersSearch from '@/views/Clients/parts/owners-search';

function NewOwner() {
  this.first_name = '';
  this.last_name = '';
  this.patronymic = '';
  this.phone = '';
  this.email = '';
  this.description = '';
}

function NewErrors() {
  this.first_name = '';
  this.last_name = '';
  this.phone = '';
}

const ownerFormSchema = object()
  .shape({
    first_name: string().required(),
    last_name: string().required(),
    phone: string().required().min(15),
    email: string().email(),
    patronymic: string(),
  });

export default {
  name: 'owner-edit-form',
  components: { OwnersSearch },
  data() {
    return {
      ownerEdit: null,
      errors: new NewErrors(),
      newOwnerFlag: false,
    };
  },
  created() {
    this.setOwnerEdit();
  },
  watch: {
    'ownerDetails.owner': function () {
      this.setOwnerEdit();
    },
  },
  computed: {
    ...mapGetters(['ownerDetails', 'carDetails', 'owners', 'ownersFilters']),
    newOwner() {
      return !this.ownerEdit?.id || this.newOwnerFlag;
    },
    showInfo() {
      return this.carDetails?.car?.owner;
    },
  },
  methods: {
    setOwnerEdit() {
      this.ownerEdit = this.ownerDetails.owner ? JSON.parse(JSON.stringify(this.ownerDetails.owner)) : new NewOwner();
    },
    setNewOwner() {
      this.ownerEdit = new NewOwner();
      this.errors = new NewErrors();
      this.newOwnerFlag = true;
    },
    saveOwner() {
      const owner = JSON.parse(JSON.stringify(this.ownerEdit));
      owner.phone = phoneUnmask(this.ownerEdit.phone);

      this.$store.dispatch(OWNER_SAVE_REQUEST, owner)
        .then(() => {
          this.$message.success('Владелец сохранен');

          if (this.newOwner) { // меняем овнера в авто
            const car = JSON.parse(JSON.stringify(this.carDetails.car));
            car.owner_id = owner.id;

            this.$store.dispatch(CAR_SAVE_REQUEST, car)
              .then(() => {
                this.$message.success('У автомобиля теперь новый владелец');
                this.$emit('update:ownerComponent', 'owner-info');
              })
              .catch(() => this.$message.error('Что-то пошло не так...'));
          } else {
            this.$emit('update:ownerComponent', 'owner-info');
          }
        })
        .catch(() => this.$message.error('Что-то пошло не так...'));
    },
    addOwner() {
      const owner = JSON.parse(JSON.stringify(this.ownerEdit));
      owner.phone = phoneUnmask(this.ownerEdit.phone);

      this.$store.dispatch(OWNER_ADD_REQUEST, owner).then((owner) => {
        this.$message.success('Новый владелец добавлен');

        const car = JSON.parse(JSON.stringify(this.carDetails.car));
        car.owner_id = owner.id;

        this.$store.dispatch(CAR_SAVE_REQUEST, car) // Добавляем овнера в авто
          .then(() => {
            this.$message.success('У автомобиля теперь есть владелец');
            this.$emit('update:ownerComponent', 'owner-info');
          })
          .catch(() => this.$message.error('Что-то пошло не так...'));
      });
    },
    validate(field) {
      ownerFormSchema
        .validateAt(field, this.ownerEdit)
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    handleValidate() {
      ownerFormSchema
        .validate(this.ownerEdit, { abortEarly: false })
        .then(() => {
          if (this.ownerEdit.id) {
            this.saveOwner();
          } else {
            this.addOwner();
          }
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors = {
              ...this.errors,
              [error.path]: error.message,
            };
          });
        });
    },
    setPhoneCode() {
      this.ownerEdit.phone = !this.ownerEdit.phone.length ? '+7' : this.ownerEdit.phone;
    },
    setOwnerFromAutocomplete(e) {
      this.ownerEdit = e;
    },
  },
};
</script>

<style scoped>

</style>
