<template>
  <div>
    <div class="flex flex__j-sb flex__a-ac flex__w mb_16">
      <p class="text_18 text_dark text_b">
        Данные автомобиля
      </p>

      <div>
        <el-button class="el-button-32" type="" @click="goBack">
          {{carEdit.id ? 'Отмена' : 'Назад'}}
        </el-button>

        <el-button :disabled="carDetails.saveLoading" class="el-button-32" type="primary" @click="saveCar">
          Сохранить
        </el-button>
      </div>
    </div>

    <div class="common__form">
      <el-form class="common__form-el" :label-position="'top'">
        <el-form-item label="Марка">
          <el-input
            v-model="carEdit.vendor"
            name="vendor"
            placeholder="Audi"
            :class="{'common__form-item-err' : errors.vendor}"
            @change="validate('vendor')"
          />
        </el-form-item>

        <el-form-item label="Модель">
          <el-input
            v-model="carEdit.model"
            name="model"
            placeholder="A4"
            :class="{'common__form-item-err' : errors.model}"
            @change="validate('model')"
          />
        </el-form-item>
      </el-form>

      <el-form class="common__form-el" :label-position="'top'">
        <el-form-item label="Регистрационный знак">
          <el-input
            v-model="carEdit.registration_number"
            name="registration_number"
            placeholder="e777ee777"
            :class="{'common__form-item-err' : errors.registration_number}"
            @change="validate('registration_number')"
          />
        </el-form-item>

        <el-form-item label="VIN">
          <el-input
            v-model="carEdit.vin"
            name="vin"
            placeholder="1KLBN52TWXM186109"
            class="text_uppercase"
            :maxlength="17"
            :show-word-limit="true"
            :class="{'common__form-item-err' : errors.vin}"
            @change="validate('vin')"
          />
        </el-form-item>
      </el-form>

      <el-form class="common__form-el" :label-position="'top'">
        <el-form-item label="Год выпуска">
          <el-input
            v-model="carEdit.year"
            name="year"
            placeholder="2015"
            :class="{'common__form-item-err' : errors.year}"
            @change="validate('year')"
          />
        </el-form-item>

        <el-form-item label="Цвет">
          <el-input
            v-model="carEdit.color"
            name="color"
            placeholder="Синий"
            clearable
            :class="{'common__form-item-err' : errors.color}"
            @change="validate('color')"
          />
        </el-form-item>
      </el-form>

      <el-form class="common__form-el" :label-position="'top'">
        <el-form-item label="Пробег">
          <el-input
            v-model="carEdit.mileage"
            name="mileage"
            placeholder="33000"
            :class="{'common__form-item-err' : errors.mileage}"
            @change="validate('mileage')"
          />
        </el-form-item>

        <div />
      </el-form>

      <div class="mt_36">
        <p class="text_18 text_b mb_16">Описание</p>

        <el-input
          type="textarea"
          :rows="3"
          placeholder="Напишите, чтобы не забыть..."
          v-model="carEdit.description">
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { object, string } from 'yup';
import { mapGetters } from 'vuex';
import { CAR_ADD_REQUEST, CAR_SAVE_REQUEST } from '@/store/actions/cars';

function NewCar() {
  this.vendor = '';
  this.model = '';
  this.vin = '';
  this.registration_number = '';
  this.year = '';
  this.color = '';
  this.mileage = '';
  this.description = '';
}

const carFormSchema = object().shape({
  vendor: string().required(),
  model: string().required(),
  vin: string().required().min(17).max(17),
  registration_number: string().required(),
  year: string().required(),
  color: string().required(),
  mileage: string().required(),
});

export default {
  name: 'car-edit-form',
  props: ['car'],
  data() {
    return {
      carEdit: null,
      errors: {
        vendor: '',
        model: '',
        vin: '',
        registration_number: '',
        year: '',
        color: '',
        mileage: '',
      },
    };
  },
  created() {
    this.setCarEdit();
  },
  watch: {
    car() {
      this.setCarEdit();
    },
  },
  computed: {
    ...mapGetters(['carDetails']),
  },
  methods: {
    setCarEdit() {
      this.carEdit = this.$route.params.id === 'new' ? new NewCar() : JSON.parse(JSON.stringify(this.car));
    },
    saveCar() {
      carFormSchema
        .validate(this.carEdit, { abortEarly: false })
        .then(() => {
          if (this.carEdit.id) {
            this.$store.dispatch(CAR_SAVE_REQUEST, this.carEdit);
          } else {
            this.$store.dispatch(CAR_ADD_REQUEST, this.carEdit).then(() => {
              this.$router.push(`/clients/${this.carEdit.id}`);
            });
          }
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            console.log(error);
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    validate(field) {
      carFormSchema
        .validateAt(field, this.carEdit)
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    goBack() {
      if (this.carEdit.id) {
        this.$emit('update:carComponent', 'car-info');
      } else {
        this.$router.push('/clients');
      }
    },
  },
};
</script>

<style scoped>

</style>
