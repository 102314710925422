<template>
  <div>
    <div class="flex flex__j-sb flex__a-ac mb_24">
      <p class="text_18 text_dark text_b">
        Данные автомобиля
      </p>

      <svg class="common__svg-blue cp" width="24" height="24" @click="$emit('update:carComponent', 'car-edit-form')">
        <use xlink:href="/img/svg/symbol.svg#edit" />
      </svg>
    </div>

    <div class="common__form-div">
      <div class="common__form-el">
        <div>
          <p class="text_15 text_gray">Марка</p>
          <p class="text_15 text_dark">{{car.vendor}}</p>
        </div>

        <div>
          <p class="text_15 text_gray">Модель</p>
          <p class="text_15 text_dark">{{car.model}}</p>
        </div>
      </div>

      <div class="common__form-el">
        <div>
          <p class="text_15 text_gray">Регистрационный знак</p>
          <p class="text_15 text_dark text_uppercase">{{car.registration_number}}</p>
        </div>

        <div>
          <p class="text_15 text_gray">VIN</p>
          <p class="text_15 text_dark text_uppercase">{{car.vin}}</p>
        </div>
      </div>

      <div class="common__form-el">
        <div>
          <p class="text_15 text_gray">Год выпуска</p>
          <p class="text_15 text_dark text_uppercase">{{car.year}}</p>
        </div>

        <div>
          <p class="text_15 text_gray">Цвет</p>
          <p class="text_15 text_dark text_uppercase">{{car.color}}</p>
        </div>
      </div>

      <div class="common__form-el">
        <div>
          <p class="text_15 text_gray">Пробег</p>
          <p class="text_15 text_dark text_uppercase">{{car.mileage}}</p>
        </div>
      </div>

      <div class="mt_36">
        <p class="text_18 text_b mb_8">Описание</p>

        <p class="text_15 text_dark">
          {{car.description ? car.description : 'Напшите, чтобы не забыть...'}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'car-info',
  props: ['car'],
};
</script>

<style scoped>

</style>
