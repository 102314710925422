<template>
  <div class="car__details">
    <div class="common__white-block">
      <component :is="carComponent" :carComponent.sync="carComponent" :car="carDetails.car" />
    </div>

    <div class="common__white-block">
      <component :is="ownerComponent" :ownerComponent.sync="ownerComponent" />
    </div>
  </div>
</template>

<script>
import CarInfo from '@/views/Clients/parts/car-info';
import CarEditForm from '@/views/Clients/parts/car-edit-form';
import { mapGetters } from 'vuex';
import OwnerInfo from '@/views/Clients/parts/owner-info';
import OwnerEditForm from '@/views/Clients/parts/owner-edit-form';

export default {
  name: 'car-details',
  components: {
    OwnerEditForm, OwnerInfo, CarInfo, CarEditForm,
  },
  data() {
    return {
      carComponent: 'car-info',
      ownerComponent: 'owner-info',
      ownerEdit: false,
    };
  },
  created() {
    if (this.$route.params.id === 'new') {
      this.carComponent = 'car-edit-form';
    }
  },
  computed: {
    ...mapGetters(['carDetails']),
  },
};
</script>

<style scoped>

</style>
