<template>
  <el-form class="mb_24">
    <el-form-item>
      <el-autocomplete
        style="width: 100%"
        v-model="searchQuery"
        :fetch-suggestions="getSuggestionList"
        :trigger-on-focus="false"
        :select-when-unmatched="true"
        clearable
        placeholder="Поиск: ФИО, номер телефона"
        @clear="changeFilter"
        @select="handleSelect">

        <template slot-scope="{ item }">
          <div>
            <p class="text_15 text_dark mb_4">{{ownerFullName(item)}}</p>
            <p class="text_15 text_gray">{{item.phone | VMask('+# (###) ###-####')}}</p>
          </div>
        </template>
      </el-autocomplete>
    </el-form-item>
  </el-form>
</template>

<script>
import _ from 'lodash';
import { OWNERS_CHANGE_FILTERS, OWNERS_LIST_REQUEST } from '@/store/actions/owners';
import { mapGetters } from 'vuex';
import getUserFullName from '@/services/getUserFullName';

export default {
  name: 'owners-search',
  data() {
    return {
      searchQuery: '',
    };
  },
  created() {
    this.updateLocalFilter();
  },
  watch: {
    carsFilters: {
      handler() {
        this.updateLocalFilter();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['owners', 'ownersFilters']),
  },
  methods: {
    updateLocalFilter() {
      this.filters = JSON.parse(JSON.stringify(this.ownersFilters));
    },
    changeFilter() {
      this.filters.offset = 1;
      this.filters.search = this.searchQuery;
      this.$store.dispatch(OWNERS_CHANGE_FILTERS, this.filters);
    },
    getSuggestionList: _.debounce(function (queryString, cb) {
      this.changeFilter();

      this.$store.dispatch(OWNERS_LIST_REQUEST).then(() => {
        cb(this.owners.items);
      });
    }, 400),
    handleSelect(item) {
      this.searchQuery = getUserFullName(item).fullName;
      this.$emit('setOwnerFromAutocomplete', item);
    },
    ownerFullName(owner) {
      return getUserFullName(owner).fullName;
    },
  },
};
</script>

<style scoped>

</style>
